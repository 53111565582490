<template>
  <div>
    <Flow />
  </div>
</template>

<script>
import Flow from '@/components/market/flow.vue'

export default {
  components: {
    Flow
  }
}
</script>
