<template>
  <div class="page_container">
    <div class="title">{{ title }}资金流入</div>
    <div class="box">
      <div class="main-echarts" ref="mainCharts"></div>
      <div class="category-echarts" ref="mainCategoryCharts"></div>
    </div>
    <div class="total_container">
      <div class="total-con">
        <div class="item">
          <span>
            <i class="type" style="background-color: #c74542" />
            主力净流入
          </span>
          <span :class="zljlr >= 0 ? 'up' : 'down'">{{ zljlr }}</span>
        </div>
        <div class="item">
          <span>
            <i class="type" style="background-color: #394e5d" />
            超大单净流入
          </span>
          <span :class="cddjlr >= 0 ? 'up' : 'down'">{{ cddjlr }}</span>
        </div>
        <div class="item">
          <span>
            <i class="type" style="background-color: #6ea8af" />
            大单净流入
          </span>
          <span :class="ddjlr >= 0 ? 'up' : 'down'">{{ ddjlr }}</span>
        </div>
        <div class="item">
          <span>
            <i class="type" style="background-color: #d6896e" />
            中单净流入
          </span>
          <span :class="zdjlr >= 0 ? 'up' : 'down'">{{ zdjlr }}</span>
        </div>
        <div class="item">
          <span>
            <i class="type" style="background-color: #9accb5" />
            小单净流入
          </span>
          <span :class="xdjlr >= 0 ? 'up' : 'down'">{{ xdjlr }}</span>
        </div>
        <div class="item">
          <span></span>
          <span>
            合计：
            <span :class="+total >= 0 ? 'up' : 'down'">{{ total }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { getQueryVariable, timeData1 } from "../../util";

export default {
  name: "marketLine",
  props: {},
  data() {
    return {
      title: "大盘资金（沪深）",
      chartEL: null,
      myChart: null,
      minVal: null,
      option: {},
      categoryOption: {},
      loading: false,
      xdjlr: 0,
      zdjlr: 0,
      ddjlr: 0,
      cddjlr: 0,
      zljlr: 0,
      query: "1.000001&secid2=0.399001",
      timeData1,
    };
  },

  watch: {
    $route(to, form) {
      if (to.fullPath !== form.fullPath) {
        this.init();
      }
    },
  },
  computed: {
    total() {
      const total =
        +this.zljlr + +this.cddjlr + +this.ddjlr + +this.zdjlr + +this.xdjlr;
      return total.toFixed(4);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const { market, code } = getQueryVariable();
      if (market && code) {
        this.query = `${market}.${code}`;
        this.title = code;
      }

      this.chartEL = this.$refs.mainCharts;
      this.mainCategoryCharts = this.$refs.mainCategoryCharts;
      this.myChart = echarts.init(this.chartEL);
      this.myMainCategoryCharts = echarts.init(this.mainCategoryCharts);
      this.option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 85,
          bottom: 30,
          left: 45,
          right: 20,
        },
        xAxis: {
          type: "category",
          data: this.timeData1,
          axisLabel: {
            formatter: this.fmtAxis,
            // interval: this.fmtVal,
          },
          axisLine: {
            onZero: false,
          },
        },
        yAxis: {
          type: "value",
          name: "亿元",
          scale: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#efefef",
            },
          },
          data: [],
        },
        series: [
          {
            type: "line",
            data: [],
          },
        ],
      };
      this.categoryOption = {
        grid: {
          top: 50,
          bottom: 40,
          left: 45,
          right: 20,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: ["净主力", "净超大", "净大单", "净中单", "净小单"],
        },
        yAxis: {
          type: "value",
          name: "亿元",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#efefef",
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: 35,
            itemStyle: {
              normal: {
                color: function (prams) {
                  if (prams.data > 0) {
                    return "#ff4d4f";
                  }
                  return "#52c41a";
                },
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: function (prams) {
                      if (prams.data > 0) {
                        return "#ff4d4f";
                      }
                      return "#52c41a";
                    },
                    fontSize: 14,
                  },
                },
              },
            },
          },
        ],
      };
      this.getData();
    },

    getData() {
      this.loading = true;
      let url = `https://push2.eastmoney.com/api/qt/stock/fflow/kline/get?lmt=0&klt=1&secid=${
        this.query
      }&fields1=f1,f2,f3,f7&fields2=f51,f52,f53,f54,f55,f56,f57,f58,f59,f60,f61,f62,f63&r=${new Date().getTime()}`;
      this.$axios.get(url).then((res) => {
        this.loading = false;
        let dataList = res.data.data.klines;
        if(res.data.data.name !== "上证指数") {
          this.title = res.data.data.name;
        }
        let data1 = [0];
        let data2 = [0];
        let data3 = [0];
        let data4 = [0];
        let data5 = [0];

        if (dataList && dataList.length > 0) {
          dataList.forEach((el) => {
            let arr = el.split(",");
            data1.push((arr[1] / 100000000).toFixed(4));
            data2.push((arr[2] / 100000000).toFixed(4));
            data3.push((arr[3] / 100000000).toFixed(4));
            data4.push((arr[4] / 100000000).toFixed(4));
            data5.push((arr[5] / 100000000).toFixed(4));
          });
          this.option.legend = {
            show: true,
          };

          this.option.series = [
            {
              type: "line",
              name: "主力净流入",
              data: data1,
              symbol: "none",
            },
            {
              type: "line",
              name: "超大单净流入",
              data: data5,
              symbol: "none",
            },
            {
              type: "line",
              name: "大单净流入",
              data: data4,
              symbol: "none",
            },
            {
              type: "line",
              name: "中单净流入",
              data: data3,
              symbol: "none",
            },
            {
              type: "line",
              name: "小单净流入",
              data: data2,
              symbol: "none",
            },
          ];
          this.zljlr = +((+data1[data1.length - 1]).toFixed(2));
          this.cddjlr = +((+data5[data5.length - 1]).toFixed(2));
          this.ddjlr = +((+data4[data4.length - 1]).toFixed(2));
          this.zdjlr = +((+data3[data3.length - 1]).toFixed(2));
          this.xdjlr = +((+data2[data2.length - 1]).toFixed(2));
          this.categoryOption.series[0].data = [
            this.zljlr,
            this.cddjlr,
            this.ddjlr,
            this.zdjlr,
            this.xdjlr,
          ];
          this.myChart.setOption(this.option);
          this.myMainCategoryCharts.setOption(this.categoryOption);
        } else {
          this.option.series = [
            {
              type: "line",
              name: "主力净流入",
              data: data1,
              symbol: "none",
            },
            {
              type: "line",
              name: "超大单净流入",
              data: data5,
              symbol: "none",
            },
            {
              type: "line",
              name: "大单净流入",
              data: data4,
              symbol: "none",
            },
            {
              type: "line",
              name: "中单净流入",
              data: data3,
              symbol: "none",
            },
            {
              type: "line",
              name: "小单净流入",
              data: data2,
              symbol: "none",
            },
          ];
          this.myMainCategoryCharts.setOption(this.categoryOption);
          this.myChart.setOption(this.option);
        }
      });
    },
    fmtAxis(val) {
      if (val == "11:30") {
        return "11:30/13:00";
      } else {
        return val;
      }
    },
    fmtVal(ind, val) {
      let arr = ["09:30", "10:30", "11:30", "14:00", "15:00"];
      if (arr.indexOf(val) != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.page_container {
  padding-bottom: 16px;
}
.title {
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #efefef;
  background-color: #efefef;
}
.box {
  width: 100%;
  height: 100%;
  margin-top: 16px;
}
.main-echarts {
  width: 100%;
  height: 260px;
}
.category-echarts {
  width: 100%;
  height: 240px;
}
.type {
  display: inline-block;
  width: 14px;
  height: 14px;
}
.total_container {
  padding: 0 16px;
}
.total-con {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  /* font-size: .14rem; */
  padding: 4px 16px;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
}
.item {
  width: 100%;
  flex-shrink: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
}
.item span {
  display: flex;
  align-items: center;
}
.item i {
  margin-right: 8px;
}
.item:last-child {
  border: 0;
}
</style>
